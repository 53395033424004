import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 5×2\\@90%1RM`}</p>
    <p>{`Strict CTB Pullups 5×2 `}<em parentName="p">{`(weighted if possible)`}</em></p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-HSPU’s`}</p>
    <p>{`10-Bodyweight Rows `}<em parentName="p">{`(lying under barbell racked at hip height)`}</em></p>
    <p>{`15-V Ups`}</p>
    <p>{`15-Back Extensions`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      